import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "components/error/ErrorBoundary";
import Footer from "components/shared/Footer";
import AlertsProvider from "components/shared/AlertsProvider";

function FooterApp() {
  return (
    <ErrorBoundary>
      <AlertsProvider>
        <Footer/>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(
  <FooterApp/>,
  document.getElementById("react-footer"),
);
