import React from "react";
import {Footer as FooterFromLibrary} from "workfinder-components";
import {BrowserRouter as Router, NavLink} from "react-router-dom";

const urls = {
  workfinderUrl: Variables.root_urls.workfinder.replace(/\/$/, ""),
  superpowerUrl: Variables.root_urls.superpower.replace(/\/$/, ""),
};

const Footer = ():JSX.Element => {
  return (
    <Router>
      <FooterFromLibrary
        NavLink={NavLink}
        {...urls}
      />
    </Router>
  );
};

export default Footer;
